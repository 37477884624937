import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { nameTitleOptions } from "../../../../utilities/constants";
import { calculate_age } from "../../../../utilities/commonFunctions";
import LaInput from "../../../../components/LaInput";
import LaAddress from "../../../../components/LaAddress";
import LaMobile from "../../../../components/LaMobile/LaMobile";

function PassengerLitigationFriend({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  claimantInfo,
}) {
  const [currentAge, setCurrentAge] = useState(false);

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  const addressProps = {
    ...props,
    keyNameCity: "gcity",
    keyNameRegion: "gregion",
    keyNamePostcode: "gpostalcode",
    keyNameAddress1: "gaddress1",
    keyNameAddress2: "gaddress2",
    keyNameAddress3: "gaddress3",
    placeholder: "Enter Postcode",
    formik,
  };

  useEffect(() => {
    const age = calculate_age(formik.values.gdob);
    setCurrentAge(age);
  }, [formik.values.gdob]);

  const fillClaimantInfo = () => {
    formik.setFieldValue("gtitle", claimantInfo.title);
    formik.setFieldValue("gfirstname", claimantInfo.firstname);
    formik.setFieldValue("gmiddlename", claimantInfo.middlename);
    formik.setFieldValue("glastname", claimantInfo.lastname);
    formik.setFieldValue("gdob", claimantInfo.dob);
    formik.setFieldValue("gaddress1", claimantInfo.address.address1);
    formik.setFieldValue("gaddress2", claimantInfo.address.address2);
    formik.setFieldValue("gaddress3", claimantInfo.address.address3);
    formik.setFieldValue("gcity", claimantInfo.address.city);
    formik.setFieldValue("gregion", claimantInfo.address.region);
    formik.setFieldValue("gpostalcode", claimantInfo.address.postalcode);
    formik.setFieldValue("gmobile", claimantInfo.mobile);
    formik.setFieldValue("glandline", claimantInfo.landline);
    formik.setFieldValue("gemail", claimantInfo.email);
  };

  const handleMaxDate = (e, key) => {
    // max date should be yesterday
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (e.target.value > yesterday.toISOString().split("T")[0]) {
      formik.setFieldValue(key, yesterday.toISOString().split("T")[0]);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  return (
    <>
      <div className="Panel__Heading">Litigation Friend</div>
      <div className="p-col-12" onClick={fillClaimantInfo}>
        <p style={{ cursor: "pointer", color: "#555", fontSize: "11px" }}>
          --- Is the main claimant ---
        </p>
      </div>
      <div className="p-col-12">
        <label className="p-d-block">Name</label>
        <Dropdown
          id="gtitle"
          name="gtitle"
          options={nameTitleOptions}
          optionLabel="name"
          placeholder="Select a Title"
          value={values.gtitle}
          onChange={onChange}
          className={classNames({
            "p-invalid": isFormFieldValid("gtitle"),
          })}
        />
        {getFormErrorMessage("gtitle")}
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="gfirstname" placeholder="First Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="gmiddlename" placeholder="Middle Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="glastname" placeholder="Last Name" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Date of Birth</label>
        <InputText
          type={"date"}
          id="gdob"
          placeholder="Date of Birth"
          name="gdob"
          value={values.gdob}
          onChange={(e) => {
            handleMaxDate(e, "gdob");
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("gdob"),
            },
            "Width__50"
          )}
        />
        {getFormErrorMessage("gdob")}
      </div>
      {formik.values.gdob && (
        <div className="p-col-12">
          <span className="label-info">Age {currentAge}</span>
        </div>
      )}
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Mobile</label>
        <LaMobile keyName="gmobile" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Landline</label>
        <LaInput keyName="glandline" placeholder="Landline" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Email</label>
        <LaInput keyName="gemail" placeholder="Email" {...props} />
      </div>

      <LaAddress {...addressProps} />
    </>
  );
}

export default PassengerLitigationFriend;
