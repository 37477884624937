import React from "react";
import OicClaimantDetails from "./OICFormComponents/OicClaimantDetails";
import OicAccidentDetails from "./OICFormComponents/OicAccidentDetails";
import OicResponsibleParties from "./OICFormComponents/OicResponsibleParties";
import OicInjuryDetails from "./OICFormComponents/OicInjuryDetails";
import OicOtherLosses from "./OICFormComponents/OicOtherLosses";
import OicStatementofTruth from "./OICFormComponents/OicStatementofTruth";
import { useUserCategory } from "../../useUserCategory";

function OicFields({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  edit,
}) {
  const userCategoryName = useUserCategory();
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  if (userCategoryName === "Introducer") {
    return null;
  }

  if (!edit) {
    return null;
  }

  return (
    <div style={{ marginBottom: "5%" }}>
      <div style={{ marginTop: "5%" }}>
        <h6
          style={{
            color: "#428BCA",
            fontWeight: "600",
            lineHeight: "1.42857143",
          }}
        >
          OIC Fields
        </h6>
        <small style={{ color: "#999" }}>Enter accident information</small>
      </div>
      <hr className="border__hr" />

      <div className="row">
        <div className="p-fluid p-formgrid p-grid p-pr-4">
          <div className="p-col-4 p-mt-2">
            <OicClaimantDetails {...props} />
            <OicAccidentDetails {...props} />
          </div>

          <div className="p-col-4 p-mt-2">
            <OicResponsibleParties {...props} />
            <OicInjuryDetails {...props} />
          </div>
          <div className="p-col-4 p-mt-2">
            <OicOtherLosses {...props} />
            <OicStatementofTruth {...props} />
          </div>
        </div>
      </div>

      {/* Continue adding the rest of the fields following the same pattern */}

      <hr className="border__hr" />
    </div>
  );
}

export default OicFields;
