import { eService } from "../../services/ESign";
import { GETEFIELDSSUCCESS, GETEFIELDSERROR } from "../slices/eSignSlice";
import { SETLOADING } from "../slices/loadingSlice";
import store from "../store";

export const getESigns =
  async (code, compaignName, pass, encryptedUrl, url) => async (dispatch) => {
    try {
      store.dispatch(SETLOADING(true));
      const res = await eService.getESigns(
        code,
        compaignName,
        pass,
        encryptedUrl,
        url
      );
      dispatch(GETEFIELDSSUCCESS(res));
      store.dispatch(SETLOADING(false));
    } catch (err) {
      dispatch(GETEFIELDSERROR(err));
      store.dispatch(SETLOADING(false));
    }
  };
