import { useFormik } from "formik";
import WitnessRequestForm from "./WitnessRequestForm";
import { Button } from "primereact/button";

function WitnessRequest({
  handleWitnessRequest,
  singleWitnessRequest,
  isWitnessEdit,
}) {
  const initialValues = {
    postalcode: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    region: "",
    name: "",
    phoneNo: "",
    email: "",
  };

  const formik = useFormik({
    initialValues: singleWitnessRequest || initialValues,

    onSubmit: (data) => {
      handleWitnessRequest(data, isWitnessEdit);
      formik.resetForm();
      console.log("data", data);
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,

    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <WitnessRequestForm {...props} />
        </div>
        <div className="p-d-flex p-jc-end p-mt-3">
          <Button
            type="button"
            className="btn btn-primary mr-2"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>

        <hr className="border__hr" />
      </div>
    </form>
  );
}
export default WitnessRequest;
