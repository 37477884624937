import React from "react";
import LaStaticDropdown from "../../../components/LaStaticDropdown";
import LaDropdown from "../../../components/LaDropdown";

function OicOtherLosses({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>Other Losses</h6>
        <hr className="border__hr" />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Is Car Repaired?</label>
        <LaStaticDropdown
          keyName="carRepairingYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Reason</label>
        <LaDropdown
          keyName="tblExplanationReasonId1"
          optionLabel="name"
          url={"/lovExplanationReason"}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Temp Replacement Vehicle?</label>
        <LaStaticDropdown
          keyName="tempReplacementVehicleYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Reason</label>
        <LaDropdown
          keyName="tblExplanationReasonId1"
          optionLabel="name"
          url={"/lovExplanationReason"}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Claim For Losses?</label>
        <LaStaticDropdown
          keyName="claimForLossesYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
    </>
  );
}

export default OicOtherLosses;
