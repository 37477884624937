import React from "react";
import LaInput from "../../../components/LaInput";
import LaDropdown from "../../../components/LaDropdown";
import LaStaticDropdown from "../../../components/LaStaticDropdown";
import LaCheckbox from "../../../components/LaCheckbox";
import LaAddress from "../../../components/LaAddress";

function ProfileForm({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const changeUserCategory = (e) => {
    if (e.value === "2") {
      formik.setFieldValue("vat", true);
    } else {
      formik.setFieldValue("vat", false);
    }
    formik.handleChange(e);
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postcode",
    keyNameAddress1: "addressline1",
    keyNameAddress2: "addressline2",
    keyNameAddress3: "addressline3",
    placeholder: "Enter Postcode",
    formik,
  };

  return (
    <>
      <div className="p-fluid p-formgrid p-grid p-px-4">
        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Name</label>
          <LaInput keyName="name" placeholder="Name" {...props} />
        </div>
        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Tag</label>
          <LaInput keyName="tag" placeholder="Tag" {...props} />
        </div>
        <div className="p-col-4 p-mt-2">
          <label className="p-d-block">Introducer Category</label>
          <LaDropdown
            keyName="userCategoryCode"
            optionLabel="name"
            url={"/lovUserCategory"}
            {...props}
            onChange={changeUserCategory}
          />
        </div>
        <div className="p-col-4 p-mt-2">
          <label className="p-d-block">Jurisdiction</label>
          <LaStaticDropdown
            keyName="jurisdiction"
            optionLabel="label"
            options={[
              { value: "E", label: "England" },
              { value: "S", label: "Scotland" },
            ]}
            {...props}
          />
        </div>
        <div className="p-col-4 p-mt-2">
          <label className="p-d-block">BDM User</label>
          <LaDropdown
            keyName="bdmuser"
            optionLabel="name"
            url={"lovInternalUser"}
            {...props}
          />
        </div>
        {values.userCategoryCode === "1" || values.userCategoryCode === "4" ? (
          <>
            <div className="p-col-6 p-mt-2">
              <label className="p-d-block">Bill to Email</label>
              <LaInput keyName="billtoemail" {...props} />
            </div>
            <div className="p-col-6 p-mt-2">
              <label className="p-d-block">Bill to Name</label>
              <LaInput keyName="billtoname" {...props} />
            </div>
          </>
        ) : null}
        {values.userCategoryCode === "2" || values.userCategoryCode === "4" ? (
          <>
            <div className="p-col-6 p-mt-2">
              <label className="p-d-block">Account Email</label>
              <LaInput keyName="accountemail" {...props} />
            </div>
            <div className="p-col-6 p-mt-2">
              <label className="p-d-block">Secondary Account Email</label>
              <LaInput keyName="secondaryaccountemail" {...props} />
            </div>
          </>
        ) : null}
        <div className="p-col-3 p-mt-2">
          <label className="p-d-block">Email</label>
          <LaInput keyName="email" placeholder="Email" {...props} />
        </div>
        <div className="p-col-3 p-mt-2">
          <label className="p-d-block">Contact Person</label>
          <LaInput
            keyName="contactperson"
            placeholder="Contact Person"
            {...props}
          />
        </div>
        <div className="p-col-3 p-mt-2">
          <label className="p-d-block">Contact Number 1</label>
          <LaInput keyName="phone" placeholder="Contact Number" {...props} />
        </div>
        <div className="p-col-3 p-mt-2">
          <label className="p-d-block">Contact Number 2</label>
          <LaInput keyName="phone2" placeholder="Contact Number" {...props} />
        </div>
        <LaAddress {...addressProps} />
        <div className="p-col-3 p-mt-2 d-flex align-items-center">
          <LaCheckbox keyName="directIntroducer" {...props} />
          <label className="p-d-block p-ml-2">Direct Introducer</label>
        </div>
        <div className="p-col-3 p-mt-2 d-flex align-items-center">
          <LaCheckbox
            keyName="vat"
            {...props}
            disabled={values.userCategoryCode === "2"}
          />
          <label className="p-d-block p-ml-2">VAT</label>
        </div>
        {values.vat === "Y" || values.vat === true ? (
          <div className="p-col-3 p-mt-2">
            <label className="p-d-block">Vat reg no.</label>
            <LaInput keyName="varegno" placeholder="varegno" {...props} />
          </div>
        ) : null}
        <div className="p-col-3 p-mt-2">
          <label className="p-d-block">Company Status</label>
          <LaStaticDropdown
            keyName="companystatus"
            optionLabel="label"
            options={[
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
            ]}
            {...props}
          />
        </div>
      </div>
    </>
  );
}

export default ProfileForm;
