import { Dialog } from "primereact/dialog";
import React from "react";
import EsignPasswordForm from "./EsignPasswordForm";

function EsignPassword({ showModal, onSubmitPassword }) {
  return (
    <Dialog
      header="Enter Password"
      position="center"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
    >
      <div className="mt-4">
        <EsignPasswordForm onSubmitPassword={onSubmitPassword} />
      </div>
    </Dialog>
  );
}

export default EsignPassword;
