import React, { useEffect } from "react";
import { useFormik } from "formik";
import LaAddress from "../../components/LaAddress";

function HdrJointTenantAddress({
  sethdrJointTenantAddressobj,
  hdr,
  hdrJointTenantAddressobj,
}) {
  const initialValues = {
    taddress1: "",
    taddress2: "",
    taddress3: "",
    tcity: "",
    tregion: "",
    tpostalcode: "",
  };

  const formik = useFormik({
    // validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
  };

  const addressProps = {
    ...props,
    keyNameCity: "tcity",
    keyNameRegion: "tregion",
    keyNamePostcode: "tpostalcode",
    keyNameAddress1: "taddress1",
    keyNameAddress2: "taddress2",
    keyNameAddress3: "taddress3",
    placeholder: "Enter Postcode",
    formik,
  };

  useEffect(() => {
    sethdrJointTenantAddressobj({
      ...hdrJointTenantAddressobj,
      ...formik.values,
    });
  }, [formik.values]);

  useEffect(() => {
    if (hdr) {
      formik.setFieldValue(
        "taddress1",
        hdr?.tblHdrjointtenancies?.[0]?.taddress1
      );
      formik.setFieldValue(
        "taddress2",
        hdr?.tblHdrjointtenancies?.[0]?.taddress2
      );
      formik.setFieldValue(
        "taddress3",
        hdr?.tblHdrjointtenancies?.[0]?.taddress3
      );
      formik.setFieldValue("tcity", hdr?.tblHdrjointtenancies?.[0]?.tcity);
      formik.setFieldValue("tregion", hdr?.tblHdrjointtenancies?.[0]?.tregion);
      formik.setFieldValue(
        "tpostalcode",
        hdr?.tblHdrjointtenancies?.[0]?.tpostalcode
      );
    }
  }, [hdr]);

  return <LaAddress {...addressProps} />;
}

export default HdrJointTenantAddress;
