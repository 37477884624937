import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PdfDowaload from "./PdfDowaload";
import { replace } from "formik";

function PdfViewer({ doc }) {
  const pdfRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  // This state is used to store the width for our PDF
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // replace \ with /

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }

  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }

  function handleNext() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }

  // Determine the width for our PDF based on window width
  const pdfWidth = Math.min(windowWidth * 0.9, 800); // Here, I'm making sure the max width of the PDF is 800px, but you can adjust as needed

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  if (!doc) {
    return null;
  }

  const formatDoc = doc.replace(/\\/, "//").replace(/\\/g, "/");
  console.log("formatDoc", formatDoc);

  return (
    <PDFSection ref={pdfRef}>
      <PDFViewerContainer>
        <PdfDowaload doc={formatDoc} />
        <PDFFooter>
          <ButtonsContainer>
            <StyledButton onClick={handleZoomIn} disabled={pageScale >= 3}>
              <FaPlus />
              <IconText>Zoom +</IconText>
            </StyledButton>
            <StyledButton onClick={handleZoomOut} disabled={pageScale <= 0.3}>
              <FaMinus />
              <IconText>Zoom -</IconText>
            </StyledButton>
          </ButtonsContainer>
          <div>
            Page {pageNumber} of {totalPages}
          </div>
          <ButtonsContainer>
            <StyledButton onClick={handlePrevious} disabled={pageNumber === 1}>
              <FaArrowLeft />
              <IconText>‹ Previous</IconText>
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={pageNumber === totalPages}
            >
              <FaArrowRight />
              <IconText>Next ›</IconText>
            </StyledButton>
          </ButtonsContainer>
        </PDFFooter>
        <StyledDiv>
          <Document file={`${formatDoc}`} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              width={pdfWidth}
              pageNumber={pageNumber}
              scale={pageScale}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </StyledDiv>
        <PDFFooter>
          <ButtonsContainer>
            <StyledButton onClick={handleZoomIn} disabled={pageScale >= 3}>
              <FaPlus />
              <IconText>Zoom +</IconText>
            </StyledButton>
            <StyledButton onClick={handleZoomOut} disabled={pageScale <= 0.3}>
              <FaMinus />
              <IconText>Zoom -</IconText>
            </StyledButton>
          </ButtonsContainer>
          <div>
            Page {pageNumber} of {totalPages}
          </div>
          <ButtonsContainer>
            <StyledButton onClick={handlePrevious} disabled={pageNumber === 1}>
              <FaArrowLeft />
              <IconText>‹ Previous</IconText>
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={pageNumber === totalPages}
            >
              <FaArrowRight />
              <IconText>Next ›</IconText>
            </StyledButton>
          </ButtonsContainer>
        </PDFFooter>
      </PDFViewerContainer>
    </PDFSection>
  );
}

const PDFSection = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
`;

const PDFViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PDFFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:disabled {
    background-color: #c3c3c3;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: #0056b3;
  }
`;

const IconText = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }
`;

const StyledDiv = styled.div`
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  overflow-x: scroll;
`;

export default PdfViewer;
