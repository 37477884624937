import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import Client from "./Client";
import VehiclesDetails from "./VehiclesDetails";
import Lender from "./Lender";
import DealershipDetails from "./DealershipDetails";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./PCPConstants";
import MainInfo from "../../components/MainInfo/MainInfo";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";
import ClaimantDetails from "./pcpFormComponents/ClaimantDetails";
import ClaimantVehicle from "./pcpFormComponents/ClaimantVehicle";
import AgreementDetails from "./pcpFormComponents/AgreementDetails";
import JointAgreement from "./jointAgreement/JointAgreement";
import JointAgreementTable from "./jointAgreement/JointAgreementTable";

function PCP({
  edit,
  onSave,
  isSubmitFormik,
  handleReturnEdit,
  setIsSubmitForm,
  showAccSubmissionCriteria,
}) {
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(
    showAccSubmissionCriteria || false
  );
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});
  const [passengersData, setPassengersData] = useState([]);
  const [singlePassenger, setSinglePassenger] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [claimantAdress, setClaimantAdress] = useState({});
  const [claimantInfo, setClaimantInfo] = useState({});
  const [claimantVehicleInfo, setClaimantVehicleInfo] = useState({});
  const [passengerIndex, setPassengerIndex] = useState(false);

  const { pcp } = useSelector((state) => state.pcpSlice);

  useEffect(() => {
    if (isSubmitFormik && edit) {
      formik.handleSubmit();
    }
  }, [isSubmitFormik]);

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["introducer"] = accSubmissionCriteria?.introducer?.companycode;
      data["advisor"] = accSubmissionCriteria?.advisor?.usercode;
      let passenData = passengersData;
      if (passenData.length) {
        passenData.forEach((passenger) => {
          Object.keys(passenger).forEach((key) => {
            if (passenger[key] === true) {
              passenger[key] = "Y";
            }
            if (passenger[key] === false) {
              passenger[key] = "N";
            }
            if (passenger[key] === "") {
              passenger[key] = null;
            }
          });
        });
      }
      data["otherPcpClaims"] = passenData;
      data["injlength"] = data["injlength"]?.toString();

      Object.keys(data).forEach((key) => {
        if (data[key] === true) {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      if (edit) {
        await handlePostRequest(data, "/Pcp/updatePcpCase");
        handleReturnEdit();
      } else {
        await handlePostRequest(data, "/Pcp/addNewPcpCase");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, pcp]);

  const fillFormFields = () => {
    Object.keys(pcp).forEach((key) => {
      formik.setFieldValue(key, pcp[key] || "");
    });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  //show formik errors
  useEffect(() => {
    if (formik.errors && setIsSubmitForm) {
      setIsSubmitForm(false);
    }
  }, [formik.errors, setIsSubmitForm]);

  const handlePassengers = (data, editMode) => {
    if (editMode) {
      let p = passengersData;
      p[passengerIndex] = data;
      setPassengersData(p);
    } else {
      setPassengersData([...passengersData, data]);
    }
    setIsEdit(false);
  };

  const onEdit = (index) => {
    setPassengerIndex(index);
    setSinglePassenger(passengersData[index]);
    setIsEdit(true);
  };

  const setEditFalse = () => {
    setIsEdit(false);
  };

  const onRemove = (index) => {
    setPassengersData((passengersData) =>
      passengersData.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    setClaimantAdress({
      city: formik.values.city,
      region: formik.values.region,
      postalcode: formik.values.postalcode,
      address1: formik.values.address1,
      address2: formik.values.address2,
      address3: formik.values.address3,
      postcode: formik.values.postalcode,
    });
  }, [
    formik.values.city,
    formik.values.region,
    formik.values.postalcode,
    formik.values.address1,
    formik.values.address2,
    formik.values.address3,
  ]);

  useEffect(() => {
    setClaimantInfo({
      title: formik.values.title,
      firstname: formik.values.firstname,
      middlename: formik.values.middlename,
      lastname: formik.values.lastname,
      dob: formik.values.dob,
      address: claimantAdress,
    });
  }, [
    formik.values.title,
    formik.values.firstname,
    formik.values.lastname,
    formik.values.dob,
    formik.values.middlename,
    claimantAdress,
  ]);

  useEffect(() => {
    setClaimantVehicleInfo({
      registration: formik.values.registerationno,
      make: formik.values.makemodel,
      model: formik.values.yearofmanufacture,
    });
  }, [
    formik.values.registerationno,
    formik.values.makemodel,
    formik.values.yearofmanufacture,
  ]);

  console.log("claimantvehicleinfo", claimantVehicleInfo);

  return (
    <>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.PCP}
        />
      ) : (
        <div className="p-ml-4">
          <MainInfo edit={edit} formik={formik} />

          <div>
            <hr className="border__hr" />
            <div className="row">
              <div className="p-col-6 p-mt-2">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ClaimantDetails {...props} />
                  <hr className="border__hr" />
                  <ClaimantVehicle {...props} />
                </div>
              </div>
              <div className="p-col-6 p-mt-4">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <AgreementDetails {...props} />
                </div>
              </div>
              <hr className="border__hr" />
              <div className="p-col-12 p-mt-4">
                {passengersData && passengersData.length > 0 && (
                  <div className="p-field p-col-12">
                    <JointAgreementTable
                      data={passengersData}
                      onRemove={onRemove}
                      onEdit={onEdit}
                      onUpdate={edit}
                    />
                  </div>
                )}
                <JointAgreement
                  handlePassengers={handlePassengers}
                  claimantAdress={claimantAdress}
                  singlePassenger={singlePassenger}
                  isEdit={isEdit}
                  onUpdate={edit} //for edit when rta is updating so hide the add button
                  setEditFalse={setEditFalse}
                  claimantInfo={claimantInfo}
                  claimantVehicleInfo={claimantVehicleInfo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PCP;
