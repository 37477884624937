import * as Yup from "yup";

const initialValues = {
  dob: "",
  email: "",
  mobile: "",
  ninumber: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  registerationno: "",
  makemodel: "",
  yearofmanufacture: "",
  insurer: "",
  policyno: "",
  refno: "",
  creditorName: "",
  brokerName: "",
  vehicleValue: "",
  creditAmount: "",
  monthlyRepayment: "",
  balloonPaymentAmount: "",
  agreementReferenceNo: "",
  loanTerm: "",
};

const validationSchema = Yup.object().shape({
  dob: Yup.string().required("Required"),
});

const jointAgreementKeys = {
  dob: "",
  email: "",
  mobile: "",
  ninumber: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  registerationno: "",
  makemodel: "",
  yearofmanufacture: "",
  insurer: "",
  policyno: "",
  refno: "",
  creditorName: "",
  brokerName: "",
  vehicleValue: "",
  creditAmount: "",
  monthlyRepayment: "",
  balloonPaymentAmount: "",
  agreementReferenceNo: "",
  loanTerm: "",
};

const jointAgreementValidationSchema = Yup.object().shape({
  dob: Yup.string().required("Required"),
});

export {
  initialValues,
  validationSchema,
  jointAgreementKeys,
  jointAgreementValidationSchema,
};
