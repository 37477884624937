import React from "react";
import LaInput from "../../../components/LaInput";

function OicStatementofTruth({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Statement of Truth
        </h6>
        <hr className="border__hr" />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Signatory First Name</label>
        <LaInput keyName="signatoryFirstName" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Signatory Middle Name</label>
        <LaInput keyName="signatoryMiddleName" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Signatory Last Name</label>
        <LaInput keyName="signatoryLastName" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Signatory Job Title</label>
        <LaInput keyName="signatoryJobTitle" {...props} />
      </div>
    </>
  );
}

export default OicStatementofTruth;
