export const campaignConfig = {
  rta: {
    getNotesService: "rta/getAuthRtaCaseNotes",
    addNotesService: "rta/addNoteToRta",
    getApiForMessages: "rta/getAuthRtaCaseMessages/",
    postApiForMessages: "rta/resendRtaEmail",
    getApiForLogs: "rta/getRtaAuditLogs",
    addFilesService: "rta/addRtaDocument",
    deleteApi: "rta/deleteRtaDocument",
    codeNameForUpload: "rtaClaimCode",
    compaignName: "Rta",
    documentType: "rtadoccode",
    getTimelineService: "rta/getAuthRtaCaseLogs/",
    campaignLogCode: "rtalogcode",
  },
  hire: {
    // Configuration for "hire" campaign goes here
  },
  hdr: {
    // Configuration for "hdr" campaign goes here
  },
  el: {
    getNotesService: "El/getElCaseNotes",
    addNotesService: "El/addElNotes",
    getApiForMessages: "El/getElCaseMessages/",
    postApiForMessages: "El/resendElEmail",
    getApiForLogs: "El/getElAuditLogs",
    addFilesService: "El/addElDocument",
    deleteApi: "El/deleteElDocument",
    codeNameForUpload: "elClaimCode",
    compaignName: "El",
    documentType: "eldocumentscode",
    getTimelineService: "El/getAuthElCaseLogs/",
    campaignLogCode: "ellogcode",
    caseTypeForNotes: "elCode",
    tbldocuments: "tblEldocuments",
    campaignNameForMessages: "elmessagecode",
  },
  ol: {
    getNotesService: "Ol/getOlCaseNotes",
    addNotesService: "Ol/addOlNotes",
    getApiForMessages: "Ol/getOlCaseMessages/",
    postApiForMessages: "Ol/resendOlEmail",
    getApiForLogs: "Ol/getOlAuditLogs",
    addFilesService: "Ol/addOlDocument",
    deleteApi: "Ol/deleteOlDocument",
    codeNameForUpload: "olClaimCode",
    compaignName: "Ol",
    documentType: "oldocumentscode",
    getTimelineService: "Ol/getOlCaseLogs/",
    campaignLogCode: "ollogcode",
    caseTypeForNotes: "olCode",
    tbldocuments: "tblOldocuments",
    campaignNameForMessages: "olmessagecode",
  },
  pl: {
    getNotesService: "Pl/getPlCaseNotes",
    addNotesService: "Pl/addPlNotes",
    getApiForMessages: "Pl/getPlCaseMessages/",
    postApiForMessages: "Pl/resendPlEmail",
    getApiForLogs: "Pl/getPlAuditLogs",
    addFilesService: "Pl/addPlDocument",
    deleteApi: "Pl/deletePlDocument",
    codeNameForUpload: "plClaimCode",
    compaignName: "Pl",
    documentType: "pldocumentscode",
    getTimelineService: "Pl/getplCaseLogs/",
    campaignLogCode: "pllogcode",
    caseTypeForNotes: "plCode",
    tbldocuments: "tblPldocuments",
    campaignNameForMessages: "plmessagecode",
  },
  medicalNegligence: {
    getNotesService: "medneg/getMnCaseNotes",
    addNotesService: "medneg/addMnNotes",
    getApiForMessages: "medneg/getMnCaseMessages/",
    postApiForMessages: "medneg/resendMnEmail",
    getApiForLogs: "medneg/getMnAuditLogs",
    addFilesService: "medneg/addMnDocument",
    deleteApi: "medneg/deleteMnDocument",
    codeNameForUpload: "mnClaimCode",
    compaignName: "MedicalNegligence",
    documentType: "mndocumentscode",
    getTimelineService: "medneg/getMnCaseLogs/",
    campaignLogCode: "mnlogcode",
    caseTypeForNotes: "mnCode",
    tbldocuments: "tblMndocuments",
    campaignNameForMessages: "mnmessagecode",
  },
};

export const campaignsNames = {
  rta: "rta",
  hire: "hire",
  hdr: "hdr",
  el: "el",
  ol: "ol",
  pl: "pl",
  medicalNegligence: "medicalNegligence",
};

export const campaignsNotesKeys = {
  rta: {
    internal: "tblRtanotesLegalOnly",
    external: "tblRtanotes",
  },
  hire: {
    internal: "tblHirenotesLegalOnly",
    external: "tblHirenotes",
  },
  hdr: {
    internal: "tblHdrnotesLegalOnly",
    external: "tblHdrnotes",
  },
  el: {
    internal: "tblElnotesLegalOnly",
    external: "tblElnotes",
  },
  ol: {
    internal: "tblOlnotesLegalOnly",
    external: "tblOlnotes",
  },
  pl: {
    internal: "tblPlnotesLegalOnly",
    external: "tblPlnotes",
  },
  medicalNegligence: {
    internal: "tblMnnotesLegalOnly",
    external: "tblMnnotes",
  },
};

export const campaignTaskKeys = {
  rta: {
    compaignCodeKeyPostApi: "rtaCode",
    comapaignCaseTasks: "rtaCaseTasks",
    compaignTaskCodeName: "rtataskcode",
    taskUploadKeyName: "rtaClaimCode",
    taskUploadAddFileService: "rta/performTask",
    setCurrentTaskService: "rta/setCurrentTask",
  },
  el: {
    compaignCodeKeyPostApi: "elCode",
    comapaignCaseTasks: "tblEltasks",
    compaignTaskCodeName: "eltaskcode",
    taskUploadKeyName: "elClaimCode",
    taskUploadAddFileService: "El/performTask",
    setCurrentTaskService: "El/setCurrentTask",
  },
  ol: {
    compaignCodeKeyPostApi: "olCode",
    comapaignCaseTasks: "tblOltasks",
    compaignTaskCodeName: "oltaskcode",
    taskUploadKeyName: "olClaimCode",
    taskUploadAddFileService: "Ol/performTask",
    setCurrentTaskService: "Ol/setCurrentTask",
  },
  pl: {
    compaignCodeKeyPostApi: "plCode",
    comapaignCaseTasks: "tblPltasks",
    compaignTaskCodeName: "pltaskcode",
    taskUploadKeyName: "plClaimCode",
    taskUploadAddFileService: "Pl/performTask",
    setCurrentTaskService: "Pl/setCurrentTask",
  },
  medicalNegligence: {
    compaignCodeKeyPostApi: "mnCode",
    comapaignCaseTasks: "tblMntasks",
    compaignTaskCodeName: "mntaskcode",
    taskUploadKeyName: "mnClaimCode",
    taskUploadAddFileService: "medneg/performTask",
    setCurrentTaskService: "medneg/setCurrentTask",
  },
};

export const campaignCodes = {
  RTA: 1,
  HIRE: 2,
  HDR: 3,
  EL: 9,
  OL: 7,
  PL: 8,
  MN: 11,
  PCP: 5,
};
