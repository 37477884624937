import React, { useState } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";

function HireCaseReport() {
  const [loading, setLoading] = useState(false);
  const [value, onChange] = useState([new Date(), new Date()]);

  const formatDate = (input) => {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const searchCases = async () => {
    setLoading(true);
    const url = `report/getHireReport/${formatDate(value[0])}/${formatDate(
      value[1]
    )}`;

    try {
      const res = await handleGetRequest(url);
      window.open(res?.data, "_blank");
    } catch (error) {
      console.error("Failed to fetch the report:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="expanded-card p-d-flex p-jc-between">
      <div className="p-d-flex ">
        <DateTimeRangePicker
          autoFocus={true}
          format="y-MM-dd"
          onChange={onChange}
          value={value}
        />
        &nbsp;
        <Button
          disabled={loading}
          label={loading ? "Searching..." : "Search"}
          onClick={searchCases}
        />
      </div>
    </div>
  );
}

export default HireCaseReport;
