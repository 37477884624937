import React, { useState, useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { v4 as uuidv4 } from "uuid";
import { FaEdit, FaTrash } from "react-icons/fa";
import WitnessRequest from "./WitnessRequest";

function Witness({ edit, setWitnessesData }) {
  const [witnessRequests, setWitnessRequests] = useState([]);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [currentWitness, setCurrentWitness] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const addWitnessRequest = () => {
    setCurrentWitness({ id: uuidv4(), data: {} });
    setIsEditMode(false);
    setDialogVisible(true);
  };

  const editWitnessRequest = (witness) => {
    setCurrentWitness(witness);
    setIsEditMode(true);
    setDialogVisible(true);
  };

  const removeWitnessRequest = (idToRemove) => {
    setWitnessRequests(
      witnessRequests.filter((request) => request.id !== idToRemove)
    );
  };

  const handleSaveWitness = (data) => {
    if (isEditMode) {
      setWitnessRequests(
        witnessRequests.map((request) =>
          request.id === currentWitness.id ? { ...request, data } : request
        )
      );
    } else {
      setWitnessRequests([...witnessRequests, { id: currentWitness.id, data }]);
    }
    setDialogVisible(false);
  };

  const getWitnessDataForSubmit = () => {
    return witnessRequests.map((witness) => witness.data);
  };

  useEffect(() => {
    const witnessesForSubmit = getWitnessDataForSubmit();
    setWitnessesData(witnessesForSubmit);
  }, [witnessRequests, setWitnessesData]);

  if (!edit) {
    return null;
  }

  return (
    <div>
      <div>
        <div style={{ marginTop: "0%" }}>
          <h6
            style={{
              color: "#428BCA",
              fontWeight: "600",
              lineHeight: "1.42857143",
            }}
          >
            Witness Request
          </h6>
          <small style={{ color: "#999" }}>Enter Witness information</small>
        </div>
        <div className="p-d-flex p-jc-start p-mt-2">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={addWitnessRequest}
          >
            Add Witness
          </Button>
        </div>

        {witnessRequests.length > 0 && (
          <div className="p-grid">
            {witnessRequests.map((request, index) => (
              <div key={request.id} className="p-col-12 p-md-4">
                <Fieldset legend={`Witness: ${index + 1}`} className="p-mt-2">
                  <div>
                    <span style={{ color: "black" }}>
                      Name:{" "}
                      <span style={{ fontWeight: "", color: "black" }}>
                        {request.data.name || ""}
                      </span>
                    </span>
                    <br />
                    <span style={{ color: "black" }}>
                      Phone:{""}
                      <span style={{ fontWeight: "", color: "black" }}>
                        {request.data.phoneNo || ""}
                      </span>
                    </span>
                  </div>
                  <div className="p-d-flex p-jc-end p-ai-center p-mt-2">
                    <Button
                      onClick={() => editWitnessRequest(request)}
                      className="btn btn-sm p-button-text"
                      type="button"
                      style={{ color: "#3F51B5" }}
                      tooltip="Edit"
                      tooltipOptions={{ position: "top" }}
                    >
                      <FaEdit />
                    </Button>

                    <Button
                      onClick={() => removeWitnessRequest(request.id)}
                      className="btn btn-sm p-button-text"
                      type="button"
                      style={{ color: "#3F51B5" }}
                      tooltip="Delete"
                      tooltipOptions={{ position: "top" }}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </Fieldset>
              </div>
            ))}
          </div>
        )}

        <Dialog
          header={isEditMode ? "Edit Witness" : "Add Witness"}
          visible={isDialogVisible}
          style={{ width: "50vw" }}
          onHide={() => setDialogVisible(false)}
        >
          <WitnessRequest
            handleWitnessRequest={handleSaveWitness}
            singleWitnessRequest={currentWitness?.data || {}}
            isWitnessEdit={isEditMode}
          />
        </Dialog>
      </div>
    </div>
  );
}

export default Witness;
