import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import classNames from "classnames";
import JointClaimantDetails from "./JointClaimantDetails";
import {
  jointAgreementKeys,
  jointAgreementValidationSchema,
} from "../PCPConstants";
import JointAgreementClaimantVehicle from "./JointAgreementClaimantVehicle";
import JointAgreementDetails from "./JointAgreementDetails";

function JointAgreement({
  handlePassengers,
  claimantAdress,
  singlePassenger,
  isEdit,
  setEditFalse,
  onUpdate,
  claimantInfo,
}) {
  const [showPassengerModal, setShowPassengerModal] = useState(false);

  const footer = (
    <div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}
      >
        Submit
      </button>
    </div>
  );

  const formik = useFormik({
    validationSchema: jointAgreementValidationSchema,
    initialValues: jointAgreementKeys,
    onSubmit: async (data) => {
      handlePassengers(data, isEdit);
      setShowPassengerModal(false);
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  useEffect(() => {
    if (isEdit) {
      setShowPassengerModal(true);
      console.log("singlePassenger", singlePassenger);
      Object.keys(singlePassenger).forEach((key) => {
        if (formik.initialValues.hasOwnProperty(key)) {
          formik.setFieldValue(key, singlePassenger[key]);
        }
      });
    }
  }, [isEdit]);

  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "registerationno",
    keyNameMakeModel: "makemodel",
    keyNameYearOfManufacture: "yearofmanufacture",
    keyNameInsurer: "insurer",
    keyNamePolicyNo: "policyno",
    keyNameRefNo: "refno",
  };

  const useClaimantInfo = () => {
    formik.setFieldValue("address1", claimantAdress.address1);
    formik.setFieldValue("address2", claimantAdress.address2);
    formik.setFieldValue("address3", claimantAdress.address3);
    formik.setFieldValue("city", claimantAdress.city);
    formik.setFieldValue("region", claimantAdress.region);
    formik.setFieldValue("postalcode", claimantAdress.postalcode);
    fillClaimantInfo();
  };

  const fillClaimantInfo = () => {
    formik.setFieldValue("title", claimantInfo.title);
    formik.setFieldValue("firstname", claimantInfo.firstname);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <hr className="border__hr" />
        {!onUpdate && (
          <div
            className="p-mt-4"
            onClick={() => setShowPassengerModal((display) => !display)}
          >
            <p style={{ cursor: "pointer", color: "#555" }}>
              --- Joint agreements: click to add ---
            </p>
          </div>
        )}
        <div>
          <Dialog
            header="Joint Agreement"
            footer={footer}
            visible={showPassengerModal}
            style={{ width: "65%" }}
            onHide={() => {
              setShowPassengerModal(false);
              formik.resetForm();
              setEditFalse();
            }}
          >
            <TabView className="mt-2">
              <TabPanel
                style={{ fontSize: "12px" }}
                renderActiveOnly={false}
                header="Personal Details"
              >
                <div className="row" disabledForm={String(onUpdate)}>
                  <div className="col-md-12">
                    <div className="p-fluid p-formgrid p-grid p-pr-4">
                      <div className="p-col-12 p-mt-2" align="center">
                        <div className="" onClick={useClaimantInfo}>
                          <p
                            style={{
                              cursor: "pointer",
                              color: "#555",
                              fontSize: "11px",
                            }}
                          >
                            --- Use Claimant info ---
                          </p>
                        </div>
                      </div>
                      <JointClaimantDetails {...props} />
                    </div>
                  </div>
                </div>
                <hr className="border__hr" />
              </TabPanel>
              <TabPanel
                style={{ fontSize: "12px" }}
                renderActiveOnly={false}
                header="Agreement Details"
              >
                <div className="row" disabledForm={String(onUpdate)}>
                  <div className="col-md-6">
                    <div className="p-fluid p-formgrid p-grid p-pr-4">
                      <JointAgreementDetails {...props} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-fluid p-formgrid p-grid p-pr-4">
                      <JointAgreementClaimantVehicle {...vehicleRegProps} />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Dialog>
        </div>
      </form>
    </>
  );
}

export default JointAgreement;
