import React, { useState } from "react";
import styled from "styled-components";
import { FiClipboard } from "react-icons/fi";
import ESignAuditDialog from "./ESignAuditDialog";
import SideBox from "../FormLayout/SideBoxSection";

// Styled components for the button
const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Adds space between buttons */
`;

export default function ESignAuditButton({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <SideBox title="ESign Audit" className="mt-4">
      {data.map((item, index) => (
        <StyledButton
          key={index}
          type="button"
          onClick={() => {
            setSelectedItem(item);
            setShowModal(true);
          }}
        >
          <FiClipboard size={20} style={{ marginRight: "5px" }} />
          Open ESign Audit {index + 1}
        </StyledButton>
      ))}
      {showModal && (
        <ESignAuditDialog
          showModal={showModal}
          onCloseModal={onCloseModal}
          data={selectedItem}
        />
      )}
    </SideBox>
  );
}
