import * as Yup from "yup";

const minordetails = {
  gtitle: "",
  gfirstname: "",
  gmiddlename: "",
  glastname: "",
  gdob: "",
  gpostalcode: "",
  gemail: "",
  gaddress1: "",
  gaddress2: "",
  gaddress3: "",
  gcity: "",
  gregion: "",
};

const claimantdetails = {
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  dob: "",
  ninumber: "",
  englishlevel: "",
  mobile: null,
  landLine: "",
  email: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  scotland: false,
  displayBasic: "false",
  minor: "",
  showMinorModal: "",
  claimantDetails: "",
  minorDetails: "",
  accidentDetails: "",
  vehiclesDetails: "",
  images: "",
  passengers: "",
  translatordetail: "",
  password: "",
  alternativenumber: "",
  vdcfapercentage: "",
};

const accidentdetails = {
  accdate: "",
  acctime: "",
  circumcode: "",
  circumdescr: "",
  location: "",
  description: "",
  rdweathercond: "",
  driverpassenger: "",
  injclasscode: "",
  injdescr: "",
  injlevel: "",
  injdescription: "",
  injlength: "",
  ongoing: false,
  medicalinfo: false,
  medicalevidence: "",
  vehicledamage: "",
  bookingmode: "",
  bookingdate: "",
  hirestartdate: "",
  hireenddate: "",
};

const vehicledetails = {
  vehiclecondition: "",
  vehicleType: "",
  location: "",
  reportedtopolice: "",
  reportedOn: "",
  referenceNumber: "",
  registerationno: "",
  makemodel: "",
  insurer: "",
  policyno: "",
  refno: "",
  partyregno: "",
  partymakemodel: "",
  partyinsurer: "",
  partypolicyno: "",
  partyrefno: "",
  greencardno: "",
  address1: "",
  partyname: "",
  partycontactno: "",
  partyaddress: "",
  evidencedatails: "",
  alternativenumber: "",
  policycover: "",
  policyholder: "",
  recovered: "",
  recoveredby: "",
  instorage: "",
  storage: "",
  lengthoflicenceheld: "",
  detail: "",
  yearofmanufacture: "",
  partyyearofmanufacture: "",
};

const initialValues = {
  ...claimantdetails,
  ...accidentdetails,
  ...vehicledetails,
  ...minordetails,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  lastname: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  mobile: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  address1: Yup.string().required("required."),
  accdate: Yup.string().required("required."),
  acctime: Yup.string().required("required."),
  //circumcode to be object
  circumcode: Yup.mixed().required("required"),
  location: Yup.string().required("required."),
  postalcode: Yup.string().required("required."),
  registerationno: Yup.string().required("required."),
  partymakemodel: Yup.string().nullable().required("required."),
  // vdcfapercentage is not required but its max value is 25
  vdcfapercentage: Yup.number().max(25, "Max 25").nullable(),
});

export { initialValues, validationSchema };
