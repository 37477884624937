import React from "react";
// import LaAddress from "../../components/LaAddress";
import LaInput from "../../../components/LaInput";
import LaAddress from "../../../components/LaAddress";

function WitnessRequestForm({
  isFormFieldValid,
  getFormErrorMessage,
  onChange,
  values,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Address",
    formik,
  };
  return (
    <div className="row">
      <div className="p-fluid p-formgrid p-grid p-pr-4">
        <div className="p-col-12 p-mt-2">
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Name</label>
            <LaInput keyName="name" {...props} />
          </div>

          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Phone Number</label>
            <LaInput keyName="phoneNo" {...props} />
          </div>
          <LaAddress {...addressProps} />
        </div>
      </div>
    </div>
  );
}

export default WitnessRequestForm;
