import React from "react";
import LaInput from "../../../components/LaInput";

function JointAgreementDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "30px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Agreement Details
        </h6>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Name of creditor (bank or finance company)
          <span className="text-danger">*</span>
        </label>
        <LaInput keyName="creditorName" placeholder="Name" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          When did your agreement start? * *Roughly if not known exactly* *
          <span className="text-danger">*</span>
        </label>
        <LaInput keyName="agreementStartDate" type="date" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          When did your agreement end? * *Roughly if not known exactly* *
          <span className="text-danger">*</span>
        </label>
        <LaInput keyName="agreementEndDate" type="date" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Name of the car dealer / broker
          <span className="text-danger">*</span>
        </label>
        <LaInput
          keyName="brokerName"
          placeholder="Dealer/Broker Name"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Vehicle value at the time</label>
        <LaInput
          keyName="vehicleValue"
          placeholder="Vehicle Value"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Amount of credit</label>
        <LaInput
          keyName="creditAmount"
          placeholder="Credit Amount"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Monthly repayment</label>
        <LaInput
          keyName="monthlyRepayment"
          placeholder="Monthly Repayment"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Balloon payment amount?</label>
        <LaInput
          keyName="balloonPaymentAmount"
          placeholder="Balloon Payment Amount"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Credit Agreement number/reference</label>
        <LaInput
          keyName="agreementReferenceNo"
          placeholder="Agreement Reference No"
          {...props}
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Loan term i.e 4 years / 3 years</label>
        <LaInput keyName="loanTerm" placeholder="Loan Term" {...props} />
      </div>
    </>
  );
}

export default JointAgreementDetails;
