import React, { useEffect } from "react";
import { useFormik } from "formik";
import LaAddress from "../../components/LaAddress";
import * as Yup from "yup";

function HdrClaimantAddress({
  sethdrClaimantAddressobj,
  hdr,
  hdrClaimantAddressobj,
}) {
  const initialValues = {
    caddress1: "",
    caddress2: "",
    caddress3: "",
    ccity: "",
    cregion: "",
    cpostalcode: "",
  };

  const formik = useFormik({
    validationSchema: Yup.object({
      caddress1: Yup.string().required("Required"),
      ccity: Yup.string().required("Required"),
      cpostalcode: Yup.string().required("Required"),
    }),
    initialValues: initialValues,
    onSubmit: async (data) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
  };

  const addressProps = {
    ...props,
    keyNameCity: "ccity",
    keyNameRegion: "cregion",
    keyNamePostcode: "cpostalcode",
    keyNameAddress1: "caddress1",
    keyNameAddress2: "caddress2",
    keyNameAddress3: "caddress3",
    placeholder: "Enter Postcode",
    formik,
  };

  useEffect(() => {
    sethdrClaimantAddressobj({ ...hdrClaimantAddressobj, ...formik.values });
  }, [formik.values]);

  useEffect(() => {
    if (hdr) {
      formik.setFieldValue("caddress1", hdr?.tblHdrclaimants?.[0]?.caddress1);
      formik.setFieldValue("caddress2", hdr?.tblHdrclaimants?.[0]?.caddress2);
      formik.setFieldValue("caddress3", hdr?.tblHdrclaimants?.[0]?.caddress3);
      formik.setFieldValue("ccity", hdr?.tblHdrclaimants?.[0]?.ccity);
      formik.setFieldValue("cregion", hdr?.tblHdrclaimants?.[0]?.cregion);
      formik.setFieldValue(
        "cpostalcode",
        hdr?.tblHdrclaimants?.[0]?.cpostalcode
      );
    } else {
      formik.handleSubmit();
    }
  }, [hdr]);

  return (
    <form>
      <LaAddress {...addressProps} />
    </form>
  );
}

export default HdrClaimantAddress;
