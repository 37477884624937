import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./HireConstants";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { nameTitleOptions } from "../../utilities/constants";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { handleGetRequest } from "../../services/GetTemplate";
import LaAddress from "../../components/LaAddress";
import LaDropdown from "../../components/LaDropdown";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import { useNavigate } from "react-router-dom";
import LaVehicleRegHire from "../../components/LaVehicleRegHire";
import LaMobile from "../../components/LaMobile/LaMobile";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";

function Hire({ edit, onSave, isSubmitFormik, handleReturnEdit }) {
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(false);
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});
  const navigate = useNavigate();

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["introducer"] = accSubmissionCriteria?.introducer?.companycode;
      data["advisor"] = accSubmissionCriteria?.advisor?.usercode;
      //convert empty string to null
      Object.keys(data).forEach((key) => {
        if (data[key] === "") {
          data[key] = null;
        }
      });
      const res = await handlePostRequest(data, "hire/addNewHireCase");
      navigate(`/hiredetails/${res.data?.hirecode}`);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Postcode",
  };

  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "registerationno",
    keyNameMakeModel: "makemodel",
    keyNameYearOfManufacture: "yearofmanufacture",
  };

  const tpVehicleRegProps = {
    ...props,
    keyNameRegNo: "partyregno",
    keyNameMakeModel: "partymakemodel",
    keyNameYearOfManufacture: "partyyearofmanufacture",
  };

  return (
    <>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.HIRE}
        />
      ) : (
        <div className="p-ml-4">
          <form onSubmit={edit ? onSave : formik.handleSubmit}>
            {!edit ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h6
                    style={{
                      color: "#428BCA",
                      fontWeight: "600",
                      lineHeight: "1.42857143",
                    }}
                  >
                    Client Info
                  </h6>
                  <small style={{ color: "#999" }}>
                    Enter main information
                  </small>
                </div>
                <div>
                  <Button
                    type="submit"
                    icon="pi pi-arrow-circle-right"
                    iconPos="right"
                    className="btn btn-primary"
                    label="Next"
                    onClick={formik.handleSubmit}
                  ></Button>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="border__hr" />
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Main Info</div>
                    <div className="p-col-12 ">
                      <label className="p-d-block">
                        Name <span className="text-danger">*</span>
                      </label>
                      <Dropdown
                        options={nameTitleOptions}
                        optionLabel="name"
                        placeholder="Select a Title"
                        value={formik.values.title}
                        onChange={(e) => formik.setFieldValue("title", e.value)}
                        className={classNames({
                          "p-invalid": isFormFieldValid("title"),
                        })}
                      />
                      {getFormErrorMessage("title")}
                    </div>
                    {/* firstname */}
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        id="firstname"
                        placeholder="First Name"
                        name="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("firstname"),
                        })}
                      />
                      {getFormErrorMessage("firstname")}
                    </div>
                    {/* middlename */}
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        id="middlename"
                        placeholder="Middle Name"
                        name="middlename"
                        value={formik.values.middlename}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("middlename"),
                        })}
                      />
                      {getFormErrorMessage("middlename")}
                    </div>
                    {/* lastname */}
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        id="lastname"
                        placeholder="Last Name"
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("lastname"),
                        })}
                      />
                      {getFormErrorMessage("lastname")}
                    </div>

                    {/* dob */}
                    <div className="p-col-6 p-mt-2">
                      <label className="p-d-block">
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <InputText
                        type={"date"}
                        id="dob"
                        placeholder="Date of Birth"
                        name="dob"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("dob"),
                        })}
                      />
                      {getFormErrorMessage("dob")}
                    </div>

                    {/* ninumber */}
                    <div className="p-col-6 p-mt-2">
                      <label className="p-d-block">
                        National Insurance Number
                      </label>
                      <InputText
                        id="ninumber"
                        placeholder="National Insurance Number"
                        name="ninumber"
                        value={formik.values.ninumber}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("ninumber"),
                        })}
                      />
                      {getFormErrorMessage("ninumber")}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Contact details</div>
                    {/* mobile */}
                    <div className="row">
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">
                          Mobile <span className="text-danger">*</span>
                        </label>
                        <LaMobile keyName="mobile" {...props} />
                      </div>
                      {/* landLine */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Landline</label>
                        <InputText
                          id="landLine"
                          placeholder="Landline"
                          name="landLine"
                          value={formik.values.landLine}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("landLine"),
                          })}
                        />
                        {getFormErrorMessage("landLine")}
                      </div>
                      {/* email */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">Email</label>
                        <InputText
                          id="email"
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("email"),
                          })}
                        />
                        {getFormErrorMessage("email")}
                      </div>

                      <LaAddress {...addressProps} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h6
                style={{
                  color: "#428BCA",
                  fontWeight: "600",
                  lineHeight: "1.42857143",
                }}
              >
                Accident Info
              </h6>
              <small style={{ color: "#999" }}>
                Enter accident information
              </small>
            </div>
            <hr className="border__hr" />
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Main Info</div>
                    <div className="p-col-12 ">
                      <div className="row">
                        {/* accdate */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">
                            Accident Date <span className="text-danger">*</span>
                          </label>
                          <InputText
                            type={"date"}
                            id="accdate"
                            placeholder="Accident Date"
                            name="accdate"
                            value={formik.values.accdate}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("accdate"),
                            })}
                          />
                          {getFormErrorMessage("accdate")}
                        </div>
                        {/* acctime */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">
                            Accident Time <span className="text-danger">*</span>
                          </label>
                          <InputText
                            type={"time"}
                            id="acctime"
                            placeholder="Accident Time"
                            name="acctime"
                            value={formik.values.acctime}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("acctime"),
                            })}
                          />
                          {getFormErrorMessage("acctime")}
                        </div>
                        {/* circumcode */}
                        <div className="p-col-6 p-mt-2 ">
                          <label className="p-d-block">
                            Circumstances <span className="text-danger">*</span>
                          </label>
                          <LaDropdown
                            keyName="circumcode"
                            optionLabel="name"
                            placeholder="circumcode"
                            url={"/lovCircumstances"}
                            {...props}
                          />
                        </div>
                        {/* location */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">
                            Location <span className="text-danger">*</span>
                          </label>
                          <InputText
                            id="location"
                            placeholder="Location"
                            name="location"
                            value={formik.values.location}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("location"),
                            })}
                          />
                          {getFormErrorMessage("location")}
                        </div>

                        {/* description */}
                        <div className="p-col-12 p-mt-2">
                          <label className="p-d-block">Description</label>
                          <InputTextarea
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("description"),
                            })}
                          />
                          {getFormErrorMessage("description")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Other Details</div>
                    <div className="p-col-12 ">
                      <div className="row">
                        {/* rdweathercond */}
                        <div className="p-col-12 p-mt-2">
                          <label className="p-d-block">
                            Road Weather Condition
                          </label>
                          <InputTextarea
                            id="rdweathercond"
                            rows={3}
                            placeholder="Road Weather Condition"
                            name="rdweathercond"
                            value={formik.values.rdweathercond}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("rdweathercond"),
                            })}
                          />
                          {getFormErrorMessage("rdweathercond")}
                        </div>

                        {/* vehicledamage */}
                        <div className="p-col-12 p-mt-2">
                          <label className="p-d-block"> Damage</label>
                          <InputTextarea
                            id="vehicledamage"
                            rows={3}
                            placeholder=" Damage"
                            name="vehicledamage"
                            value={formik.values.vehicledamage}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("vehicledamage"),
                            })}
                          />
                          {getFormErrorMessage("vehicledamage")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h6
                style={{
                  color: "#428BCA",
                  fontWeight: "600",
                  lineHeight: "1.42857143",
                }}
              >
                Vehicle Info
              </h6>
              <small style={{ color: "#999" }}>Enter vehcile information</small>
            </div>
            <hr className="border__hr" />
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Client Info</div>
                    <div className="p-col-12 ">
                      <div className="row">
                        <LaVehicleRegHire {...vehicleRegProps} />

                        {/* vehiclecondition radiobutton */}
                        <div className="p-col-8 p-mt-2">
                          <label className="p-d-block">Vehicle Condition</label>
                          <div className="d-flex flex-row justify-content-between">
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="vehiclecondition"
                                name="vehiclecondition"
                                value="drivable"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.vehiclecondition === "drivable"
                                }
                              />
                              <label htmlFor="vehiclecondition">Drivable</label>
                            </div>
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="vehiclecondition"
                                name="vehiclecondition"
                                value="undrivable"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.vehiclecondition ===
                                  "undrivable"
                                }
                              />
                              <label htmlFor="vehiclecondition">
                                Undrivable
                              </label>
                            </div>
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="vehiclecondition"
                                name="vehiclecondition"
                                value="urgentrecovery"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.vehiclecondition ===
                                  "urgentrecovery"
                                }
                              />
                              <label htmlFor="vehiclecondition">
                                Urgent Recovery
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* recovered */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Recovered</label>
                          {/* checkbox */}
                          <Checkbox
                            inputId="recovered"
                            name="recovered"
                            value="recovered"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "recovered",
                                e.checked ? "Y" : "N"
                              );
                            }}
                            checked={formik.values.recovered === "Y"}
                          />
                          <label htmlFor="recovered" className="p-ml-2">
                            Recovered
                          </label>
                        </div>
                        {/* recoveredby*/}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Recovered By</label>
                          <InputText
                            id="recoveredby"
                            placeholder="Recovered By"
                            name="recoveredby"
                            value={formik.values.recoveredby}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("recoveredby"),
                            })}
                          />
                          {getFormErrorMessage("recoveredby")}
                        </div>
                        {/* instorage */}
                        <div className="p-col-6 p-mt-2">
                          {/* checkbox */}
                          <Checkbox
                            inputId="instorage"
                            name="instorage"
                            value="instorage"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "instorage",
                                e.checked ? "Y" : "N"
                              );
                            }}
                            checked={formik.values.instorage === "Y"}
                          />
                          <label htmlFor="instorage" className="p-ml-2">
                            In Storage
                          </label>
                        </div>
                        {/* storage */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Storage</label>
                          <InputText
                            id="storage"
                            placeholder="Storage"
                            name="storage"
                            value={formik.values.storage}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("storage"),
                            })}
                          />
                          {getFormErrorMessage("storage")}
                        </div>
                        {/* lengthoflicenceheld */}
                        <div className="p-col-12 p-mt-2">
                          <label className="p-d-block">
                            Length of Licence Held
                          </label>
                          <InputText
                            id="lengthoflicenceheld"
                            placeholder="Length of Licence Held"
                            name="lengthoflicenceheld"
                            value={formik.values.lengthoflicenceheld}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid(
                                "lengthoflicenceheld"
                              ),
                            })}
                          />
                          {getFormErrorMessage("lengthoflicenceheld")}
                        </div>

                        {/* policycover radiobuttons */}
                        <div className="p-col-8 p-mt-2">
                          <label className="p-d-block">Policy Cover</label>
                          <div className="d-flex flex-row justify-content-between">
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="policycover"
                                name="policycover"
                                value="fullycomprehensive"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.policycover ===
                                  "fullycomprehensive"
                                }
                              />
                              <label htmlFor="policycover">
                                Fully Comprehensive
                              </label>
                            </div>
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="policycover"
                                name="policycover"
                                value="TP"
                                onChange={formik.handleChange}
                                checked={formik.values.policycover === "TP"}
                              />
                              <label htmlFor="policycover">TP</label>
                            </div>
                            <div className="p-field-radiobutton">
                              <RadioButton
                                inputId="policycover"
                                name="policycover"
                                value="TPFT"
                                onChange={formik.handleChange}
                                checked={formik.values.policycover === "TPFT"}
                              />
                              <label htmlFor="policycover">
                                TP Fire And Theft
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* policyholder */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Policy Holder</label>
                          <InputText
                            id="policyholder"
                            placeholder="Policy Holder"
                            name="policyholder"
                            value={formik.values.policyholder}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("policyholder"),
                            })}
                          />
                          {getFormErrorMessage("policyholder")}
                        </div>
                        {/* insurer */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Insurer</label>
                          <InputText
                            id="insurer"
                            placeholder="Insurer"
                            name="insurer"
                            value={formik.values.insurer}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("insurer"),
                            })}
                          />
                          {getFormErrorMessage("insurer")}
                        </div>
                        {/* policyno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Policy No</label>
                          <InputText
                            id="policyno"
                            placeholder="Policy No"
                            name="policyno"
                            value={formik.values.policyno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("policyno"),
                            })}
                          />
                          {getFormErrorMessage("policyno")}
                        </div>
                        {/* refno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Reference Number</label>
                          <InputText
                            id="refno"
                            placeholder="Reference Number"
                            name="refno"
                            value={formik.values.refno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("refno"),
                            })}
                          />
                          {getFormErrorMessage("refno")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Third Party Info</div>
                    <div className="p-col-12 ">
                      <div className="row">
                        {/* partyregno */}
                        <LaVehicleRegHire {...tpVehicleRegProps} />
                        {/* partyname */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Party Name</label>
                          <InputText
                            id="partyname"
                            placeholder="Party Name"
                            name="partyname"
                            value={formik.values.partyname}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partyname"),
                            })}
                          />
                          {getFormErrorMessage("partyname")}
                        </div>

                        {/* partycontactno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Party Contact No</label>
                          <InputText
                            id="partycontactno"
                            placeholder="Party Contact No"
                            name="partycontactno"
                            value={formik.values.partycontactno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partycontactno"),
                            })}
                          />
                          {getFormErrorMessage("partycontactno")}
                        </div>
                        {/* partyaddress */}
                        <div className="p-col-12 p-mt-2">
                          <label className="p-d-block">Party Address</label>
                          <InputTextarea
                            id="partyaddress"
                            rows={3}
                            placeholder="Party Address"
                            name="partyaddress"
                            value={formik.values.partyaddress}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partyaddress"),
                            })}
                          />
                          {getFormErrorMessage("partyaddress")}
                        </div>
                        {/* greencardno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Green Card No</label>
                          <InputText
                            id="greencardno"
                            placeholder="Green Card No"
                            name="greencardno"
                            value={formik.values.greencardno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("greencardno"),
                            })}
                          />
                          {getFormErrorMessage("greencardno")}
                        </div>
                        {/* partyinsurer */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Party Insurer</label>
                          <InputText
                            id="partyinsurer"
                            placeholder="Party Insurer"
                            name="partyinsurer"
                            value={formik.values.partyinsurer}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partyinsurer"),
                            })}
                          />
                          {getFormErrorMessage("partyinsurer")}
                        </div>
                        {/* partypolicyno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Party Policy No</label>
                          <InputText
                            id="partypolicyno"
                            placeholder="Party Policy No"
                            name="partypolicyno"
                            value={formik.values.partypolicyno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partypolicyno"),
                            })}
                          />
                          {getFormErrorMessage("partypolicyno")}
                        </div>
                        {/* partyrefno */}
                        <div className="p-col-6 p-mt-2">
                          <label className="p-d-block">Party Ref No</label>
                          <InputText
                            id="partyrefno"
                            placeholder="Party Ref No"
                            name="partyrefno"
                            value={formik.values.partyrefno}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("partyrefno"),
                            })}
                          />
                          {getFormErrorMessage("partyrefno")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Hire;
