import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { nameTitleOptions } from "../../../../utilities/constants";
import { calculate_age } from "../../../../utilities/commonFunctions";
import LaInput from "../../../../components/LaInput";
import LaCheckbox from "../../../../components/LaCheckbox";
import LaAddress from "../../../../components/LaAddress";
import OptionButton from "../../../../components/LaVehicleReg/OptionButton";
import LaMobile from "../../../../components/LaMobile/LaMobile";

function PassengerClaimantDetails({
  handleLitigationFriend,
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  claimantAdress,
}) {
  const [isMinor, setisMinor] = useState(false);
  const [currentAge, setCurrentAge] = useState(false);

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  const ninumberOptions = ["Will be provided to the solicitor", "Minor"];

  const onChangeOptionNinumber = (option) => {
    formik.setFieldValue("ninumber", option);
  };
  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Postcode",
    formik,
  };

  useEffect(() => {
    let age_limit = 18;
    let age = calculate_age(values.dob);
    setCurrentAge(age);
    if (values.scotland === true) {
      age_limit = 16;
    }
    if (age < age_limit) {
      setisMinor(true);
      handleLitigationFriend(true);
    } else {
      setisMinor(false);
      handleLitigationFriend(false);
    }
  }, [values.scotland, values.dob]);

  const useClaimantAddress = () => {
    formik.setFieldValue("address1", claimantAdress.address1);
    formik.setFieldValue("address2", claimantAdress.address2);
    formik.setFieldValue("address3", claimantAdress.address3);
    formik.setFieldValue("city", claimantAdress.city);
    formik.setFieldValue("region", claimantAdress.region);
    formik.setFieldValue("postalcode", claimantAdress.postalcode);
  };

  const handleMaxDate = (e, key) => {
    // max date should be yesterday
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (e.target.value > yesterday.toISOString().split("T")[0]) {
      formik.setFieldValue(key, yesterday.toISOString().split("T")[0]);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  return (
    <>
      <div className="Panel__Heading">Passenger</div>
      <div className="p-col-12">
        <label className="p-d-block">Name</label>
        <Dropdown
          id="title"
          name="title"
          options={nameTitleOptions}
          optionLabel="name"
          placeholder="Select a Title"
          value={values.title}
          onChange={onChange}
          className={classNames({
            "p-invalid": isFormFieldValid("title"),
          })}
        />
        {getFormErrorMessage("title")}
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="firstname" placeholder="First Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="middlename" placeholder="Middle Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="lastname" placeholder="Surname" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Did accident occur in scotland?</label>
        <LaCheckbox keyName="scotland" {...props} />
      </div>

      {/* dob */}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Date of Birth</label>
        <InputText
          type="date"
          id="dob"
          placeholder="Date of Birth"
          name="dob"
          value={values.dob}
          onChange={(e) => {
            handleMaxDate(e, "dob");
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("dob"),
            },
            "Width__50"
          )}
        />
        {getFormErrorMessage("dob")}
      </div>
      {formik.values.dob && (
        <>
          {isMinor ? (
            <div className="p-col-12">
              <span className="label-danger">Minor {currentAge}</span>
            </div>
          ) : (
            <div className="p-col-12">
              <span className="label-info">Age {currentAge}</span>
            </div>
          )}
        </>
      )}

      {/* ninumber */}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">National Insurance Number</label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id="ninumber"
            placeholder="National Insurance Number"
            name="ninumber"
            value={values.ninumber}
            onChange={onChange}
            className={classNames({
              "p-invalid": isFormFieldValid("ninumber"),
            })}
          />

          <div>
            <OptionButton
              options={ninumberOptions}
              onChangeOption={onChangeOptionNinumber}
            />
          </div>
        </div>
        {getFormErrorMessage("ninumber")}
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Mobile</label>
        <LaMobile keyName="mobile" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Landline</label>
        <LaInput keyName="landline" placeholder="Landline" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Email</label>
        <LaInput keyName="email" placeholder="Email" {...props} />
      </div>
      <div className="p-col-12 p-mt-2" align="center">
        <div className="" onClick={useClaimantAddress}>
          <p style={{ cursor: "pointer", color: "#555", fontSize: "11px" }}>
            --- Use Claimant address ---
          </p>
        </div>
      </div>
      <LaAddress {...addressProps} />
    </>
  );
}

export default PassengerClaimantDetails;
